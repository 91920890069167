<template>
  <div class="helpComponent">
    <div class="row mt-3">
      <div class="col-12">
        <h3 class="help-subtitle">
          Objectif et fonctionnement du dispositif d'aide
        </h3>
        <p>L’objectif du dispositif est d’accompagner le retour vers l’emploi des artistes et techniciens jeunes parents,
          en les aidant à <strong>financer la garde de leur enfant.</strong><br>
          Il prévoit de verser cette aide <strong>jusqu’aux 4 ans de l’enfant</strong>, sous la forme d’un
          <strong>remboursement des frais de garde</strong>, sur présentation de justificatifs.
        </p>
        <p>
          L'aide financière est fonction du nombre d’heures éligibles variant en fonction
          du nombre d’heures de garde nécessaires pour la réalisation des contrats
          de travail de l’artiste ou du technicien(ne).
        </p>
        <p>
          <strong>L'aide financière permet la prise en charge jusqu’à 50%</strong> des frais de garde
          (à domicile, en crèche, chez une assistante maternelle, etc.) réalisée
          conformément aux règles en vigueur pour chaque dispositif de garde.
        </p>
        <p>
          L’aide est exclusive et est non cumulable avec d'autres aides à la garde d'enfants
          pour la même prestation, à l’exception du complément de libre choix du mode de garde de la CAF et des crédits d’impôt éventuels.
        </p>
        <p>
          Le montant annuel maximal de l’aide peut atteindre 3 000 €.<br>
          <strong>À compter du 1er juillet 2023, 6 000 € pour les familles monoparentales.</strong>
        </p>
        <p>
          <strong>
            Par ailleurs, concernant les frais de déplacement et d’hébergement de la garde d’enfants lors d’une tournée, 
            l’aide financière permet une prise en charge de 50% dans une enveloppe annuelle maximale de 1000 € par foyer.
          </strong>
        </p>
        <p>
          Si les deux parents sont éligibles aux aides ci-dessus décrites, il n'est attribué qu'une seule aide par foyer pour une même prestation.
        </p>
      </div>
    </div>
    <hr>
    <div class="row mt-3">
      <div class="col-12">
        <h3 class="help-subtitle">
          Comment en bénéficier ?
        </h3>
        <p>
          <ul>
            <li>
              <strong>
                Répondre à des conditions de ressources: 
                à compter du 1er juillet 2023, moins de <nobr>60 000 €</nobr> avec une majoration de <nobr>3 000 €</nobr> par enfant à charge.
              </strong>
            </li>
            <li>
              Avoir bénéficié <strong>d’au moins une indemnisation Pôle emploi au titre des annexes 8 et 10 au cours
              des 24 mois</strong> précédant l’année de la demande d’aide.
            </li>
            <li>
              <strong>Bénéficier d’un contrat de travail en CDD ou CDDU</strong>, au sein d’une entreprise de spectacle.
            </li>
          </ul>
        </p>
      </div>
    </div>
    <hr>
    <div class="row mt-3">
      <div class="col-12">
        <h3 class="help-subtitle">
          Comment déposer votre demande ?
        </h3>
        <p>
          Pour que nous puissions instruire votre demande d’aide financière, vous devez :
          <ul>
            <li>valider le <strong>test d'éligibilité</strong>;</li>
            <li>renseigner vos informations et <strong>créer votre compte</strong>;</li>
            <li>compléter votre profil en ajoutant vos <strong>enfants à charge</strong>.</li>
          </ul>
        </p>
        <p>
          Une fois vos informations renseignées, vous pouvez <strong>faire une demande d'accord de 
          principe pour l'année en cours</strong>. 
          Une fois l'accord de principe obtenu, vous pourrez déposer des demandes de remboursement 
          pour tous les mois suivants de l'année concernée.<br>
          Attention, la demande de remboursement pour une prestation de garde 
          réglée devra être effectuée dans un délai de 3 mois après la réalisation de la prestation.<br>
          Exemple : La demande de remboursement des frais de garde de janvier 2023 pourra être formulée 
          sur la plateforme au plus tard en avril 2023.
        </p>
        <p>
          Pour faire une demande d'accord de principe, rendez-vous sur votre tableau de bord, onglet "Mes accords de principe".<br>
          Vous pouvez ensuite joindre les pièces justificatives suivantes :
          <ul>
            <li>Une attestation sur l'honneur</li>
            <li>Le ou les acte(s) de naissance ou d'adoption de ou des enfant(s) faisant l'objet de la garde</li>
            <li>La copie intégrale de l'avis d'imposition de l'année précédente sur les revenus de l'année antérieure
              du bénéficiaire ainsi que celui du conjoint en cas de vie maritale</li>
            <li>La dernière notification d'ouverture de vos droits ARE de Pôle emploi au titre des annexes 8 ou 10</li>
            <li>Votre relevé d'identité bancaire ou postal</li>
          </ul>

          Des pièces complémentaires pourront vous être demandées si nécessaire.
        </p>
        <p>
          Une fois l'accord de principe obtenu, vous pourrez <strong>déposer une demande de remboursement
          des frais d'aide à la garde d'enfants pour chaque mois</strong>. En vous connectant sur votre tableau de bord,
          onglet "Mes demandes de remboursement", vous serez invité à choisir l'année et le mois de votre demande,
          ainsi que le(s) enfant(s) et le(s) type(s) de garde concerné(s).
        </p>
        <p>
           <strong>Les demandes de remboursements seront traitées par ordre d’arrivée (date de dépôt) et attribuées après 
           vérification de leur éligibilité dans la limite des fonds mis à disposition par le Ministère de la Culture.</strong>
        </p>
        <p>
          Selon le type de garde choisi, vous serez invités à charger directement sur la plateforme les documents associés à votre demande.
        </p>
        <p>
          Vous pouvez par ailleurs, déposer une demande de remboursement pour les
          frais d’hébergement et de déplacement de la garde d’enfant employée lors d’une tournée.
        </p>
        <p>
          Vous pouvez ensuite joindre les pièces justificatives suivantes :
          <ul>
            <li>
              le(s) contrat(s) de travail en CDD ou CDDU dans une entreprise du spectacle
              ou le(s) bulletins de salaire correspondants de l’artiste ou du technicien,
              justifiant le recours nécessaire à la garde d’enfant(s) et indiquant le nombre
              d’heures de travail ayant nécessité la garde <strong>et mentionnant la tournée</strong> ;
            </li>
            <li>
              le contrat de travail ou l’attestation de l’employeur indiquant le(s) lieu(x) de
              réalisation de la prestation dans le cadre de la tournée ;
            </li>
            <li>
              le justificatif de paiement de la garde déclarée (bulletins de salaire,
              bordereau de cotisations sociales, facture des prestations de garde…) et l’attestation de paiement des prestations CAF
            </li>
            <li>
              Si le transport et l’hébergement ne sont pas indemnisés forfaitairement :
              <ul>
                <li>
                  les justificatifs des frais de transport propres à la garde d’enfants : billet de train 2ème classe SNCF ou d’avion au nom de la garde ;
                </li>
                <li>
                  les justificatifs des frais d’hébergement et de diner au nom de la garde d’enfants, (remboursement maximum de 50 € par nuit et de 19 € par diner).
                </li>
              </ul>
            </li>
          </ul>
        </p>
        <p>
          Vous pourrez ensuite faire la <strong>demande de validation finale de votre dossier.</strong>
        </p>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12 col-sm-8 offset-sm-2">
        <b-button block v-b-toggle.fonpeps-accordion variant="outline-dark"><strong>Le FONPEPS, c'est quoi ?</strong></b-button>
        <b-collapse id="fonpeps-accordion" role="tabpanel">
          <p class="mt-3">
            Le Fonds national pour l’emploi pérenne dans le spectacle est le fonds
            créé pour soutenir activement l’emploi dans le spectacle vivant et
            enregistré. Destiné aux employeurs et aux artistes et techniciens du
            spectacle, le fonds encourage la création d’emploi via 9 mesures.
          </p>
          <p>
            Les aides portées par le Fonds agissent directement sur la création
            d’emplois en soutenant financièrement les entreprises et en consolidant l’emploi des salariés.
          </p>
          <p>
            Le Fonds est doté par l’Etat. Il est l’aboutissement d’un travail initié
            lors de la Conférence pour l’emploi d’octobre 2015.
          </p>
          > En savoir plus sur le site du
          <a href="https://www.culture.gouv.fr/Aides-demarches/Dispositifs-specifiques/Fonds-national-pour-l-emploi-perenne-dans-le-spectacle-FONPEPS" target="_blank">
            Ministère de la Culture
          </a>.
        </b-collapse>
      </div>
    </div>

    <hr>

    <contactComponent />

  </div>
</template>

<script>
import contactComponent from '@/components/contactComponent'
export default {
  name: 'helpComponent',
  components: {
    contactComponent,
  },
}
</script>
<style>

.help-subtitle {
  font-size: 18px;
  font-weight: bold;
  color: #383c48;
}

@media (min-width: 480px) {

  .help-subtitle {
    font-size: 20px;
    font-weight: bold;
  }

}


@media (min-width: 768px) {

  .help-subtitle {
    font-size: 20px;
    font-weight: bold;
  }

}

@media (min-width: 1024px) {
  .help-subtitle {
    font-size: 22px;
    font-weight: bold;
  }

}
</style>
